<template>
  <div
    id="infinite-table stick"
    :class="{ 'sticky-header-table': stickyHeader }"
  >
    <b-table
      :tbody-tr-class="rowClass"
      :sticky-header="stickyHeader"
      :id="id"
      :sort-by.sync="sortByProxy"
      :sort-desc.sync="sortDescProxy"
      :striped="striped"
      :bordered="bordered"
      :borderless="borderless"
      :outlined="outlined"
      :small="small"
      :hover="hover"
      :dark="dark"
      :fixed="fixed"
      :foot-clone="footClone"
      :no-border-collapse="noBorderCollapse"
      :items="items"
      :fields="fields"
      :head-variant="headVariant"
      :table-variant="tableVariant"
      :responsive="responsive"
      show-empty
      ref="table"
      :busy="isBusy"
      :no-local-sorting="noLocalSorting"
      @row-clicked="onRowClicked"
      @row-hovered="rowHovered"
      @row-contextmenu="onRightClick"
      @scroll.native="onScroll"
    >
      <template #table-busy>
        <div class="row m-5">
          <div class="col-12 d-flex justify-content-center">
            <GeneralLoading />
          </div>
        </div>
      </template>

      <template slot="thead-top" slot-scope="data">
        <tr v-if="tableParentFields.length">
          <template v-for="item in tableParentFields">
            <th :colspan="item.colspan" :class="item.class">
              {{ $t(item.label) }}
            </th>
          </template>
        </tr>
      </template>
      <!-- Loading Deadline Repost FINISH  -->
      <template
        v-for="(item, index) in fieldItemsData"
        #[`cell(${item.key})`]="data"
      >
        <template v-if="item.key == 'order_number'">
          {{ data.item.order_number }}
        </template>
        <template v-if="item.key == 'customer'">
          {{ data.item.customer }}
        </template>
        <template v-for="i in packingColumnCount">
          <template v-if="item.key == 'packing_date_and_amount_' + i">
            <table style="width:100%;"> 
              <tbody>
                <tr>
                  <td :class="data.item['packing_amount_' + i] >0 ? 'custom_column_date' : 'custom_column_empty'"  >{{ data.item["packing_date_" + i] }}</td>
                  <td :class="data.item['packing_amount_' + i] >0 ? 'custom_column_amount' : 'custom_column_empty'"   >{{ Number(data.item["packing_amount_" + i]).toLocaleString(
                        "tr-TR",
                        {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        }) }}
                  </td> 
                </tr>  
              </tbody>
            </table> 
          </template>
        </template>
        <template v-for="j in exportColumnCount">
          <template v-if="item.key == 'export_date_and_amount_' + j">
            <table style="width:100%;font-weight:700"> 
              <tbody>
                <tr>
                  <td :class="data.item['export_amount_' + j] > 0 ? 'custom_column_date' : 'custom_column_empty'" >{{ data.item["export_date_" + j] }}</td>
                  <td :class="data.item['export_amount_' + j] > 0 ? 'custom_column_amount' : 'custom_column_empty'" >{{ Number(data.item["export_amount_" + j]).toLocaleString(
                        "tr-TR",
                        {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        }) }}</td> 
                </tr>  
              </tbody>
            </table> 
          </template>
        </template>
      </template>
      <!-- Loading Deadline Repost END  -->

    </b-table>
    <infinite-loading
      v-if="disabledInfiniteLoading && items.length > 10"
      @infinite="onHitBottom"
      :identifier="infiniteId"
      force-use-infinite-wrapper="true"
    >
      <span slot="spinner"></span>
      <span slot="no-more">
        <h4 v-if="page > 2">{{ $t("general.no_more_records") }}</h4>
      </span>
      <span slot="no-results">
        <h4>{{ $t("general.no_records_found") }}</h4>
      </span>
    </infinite-loading>
    <div class="w-100 px-5 mb-10">
      <Pagination
        v-if="paginate"
        :paginate="paginate"
        :page.sync="pageProxy"
        :perPage.sync="perPageProxy"
      ></Pagination>
    </div>
  </div>
</template>

<script>
import Pagination from "@/assets/components/dataTable/Pagination";
import CustomMultiSelect from "@/assets/components/inputs/CustomMultiSelect";
import SelectInput from "@/assets/components/inputs/SelectInput";
import SelectFilter from "@/assets/components/filters/SelectFilter";
import GeneralLoading from "@/assets/components/loader/GeneralLoading";
export default {
  name: "dataTable",
  props: {
    id: {
      default: "my-table",
      required: false,
    },
    packingColumnCount: {
      default: false,
      required: false,
    },
    exportColumnCount: {
      default: 2,
      required: false,
    },
    selectedCheckBoxes: {
      required: false,
      default: function () {
        return [];
      },
    },
    fields: {
      default() {
        return [];
      },
      type: Array,
      required: true,
    },
    tableParentFields: {
      default() {
        return [];
      },
      type: Array,
      required: false,
    },
    items: {
      default() {
        return [];
      },
      type: Array,
      required: true,
    },
    stickyHeader: {
      required: false,
      default: false,
    },
    title: {
      default: null,
      type: String,
    },
    infiniteId: {
      default: 1,
      type: Number,
    },
    paginate: null,
    striped: {
      type: Boolean,
      default: false,
    },
    disabledInfiniteLoading: {
      type: Boolean,
      default: true,
    },
    sortBy: {
      type: String,
    },
    sortDesc: {
      required: false,
    },
    bordered: {
      type: Boolean,
      default: true,
    },
    borderless: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: true,
    },
    small: {
      type: Boolean,
      default: false,
    },
    hover: {
      type: Boolean,
      default: true,
    },
    dark: {
      type: Boolean,
      default: false,
    },
    fixed: {
      type: Boolean,
      default: false,
    },
    footClone: {
      type: Boolean,
      default: false,
    },
    headVariant: {
      type: String,
      default: "none",
    },
    tableVariant: {
      type: String,
      default: "light",
    },
    noBorderCollapse: {
      type: Boolean,
      default: false,
    },
    page: {
      default: 1,
      required: false,
    },
    perPage: {
      default: 10,
      required: false,
    },
    responsive: {
      required: false,
      default: true,
    },
    noLocalSorting: {
      required: false,
      default: true,
    },
    isBusy: {
      required: false,
      default: false,
    },
  },
  components: {
    SelectInput,
    Pagination,
    CustomMultiSelect,
    SelectFilter,
    GeneralLoading,
  },
  data() {
    return {
      selectedIndexId: null,
      tableSelected: {
        1: "Satınalma Faturası kes",
        3: "Satınalma İade Faturası kes",
        4: "Toptan Satış İade Faturası kes",
        7: "Yansıtma Faturası kes",
      },
      fieldItemsData: [],
    };
  },
  computed: {
    sortByProxy: {
      get() {
        this.fieldItemsData = this.fields;
        return this.sortBy;
      },
      set(value) {
        this.$emit("update:sortBy", value);
      },
    },
    sortDescProxy: {
      get() {
        if (this.sortDesc === "desc") {
          return true;
        }
        return false;
      },
      set(value) {
        if (value) {
          this.$emit("update:sortDesc", "desc");
        } else {
          this.$emit("update:sortDesc", "asc");
        }
      },
    },
    selectedCheckBoxesProxy: {
      set(value) {
        this.$emit("update:selectedCheckBoxes", value);
      },
      get() {
        return this.selectedCheckBoxes;
      },
    },
    perPageProxy: {
      set(value) {
        this.$emit("update:perPage", value);
      },
      get() {
        return this.perPage;
      },
    },
    pageProxy: {
      set(value) {
        this.$emit("update:page", value);
      },
      get() {
        return this.page;
      },
    },
  },

  methods: {
    rowClass(item, type) {
      if (!item || type !== "row") return; 
      let result = "";
      switch (item.row_status_and_color) {
        case 1:
          result = "table-danger";
          break;
        case 0:
          result = "table-success";
          break;
        case 2:
          result = "table-warning";
          break;
        default:
          result = "table-default";
          break;
      }

      return result;
    },
    checkboxAllClick(status) {
      this.$emit("checkboxAllClick", status);
    },
    checkboxOneClick(status) {
      this.$emit("checkboxOneClick", status);
    },
    getStatusClass(status) {
      let classes = [
        "badge-success",
        "badge-primary",
        "badge-warning",
        "badge-info",
        "badge-light",
        "badge-dark",
      ];
      return classes[status];
    },
    stockCardTypeTrans(type) {
      switch (type) {
        case 1:
          return this.$t("stock.stock");
        case 2:
          return this.$t("stock.purchase_service_card");
        case 3:
          return this.$t("stock.sales_service_card");
      }
    },
    rowHovered(item) {
      this.$emit("rowHovered", item);
      this.$refs.table.refresh();
    },
    onRowClicked(item, index, event) {
      this.$emit("rowClicked", item, index, event);
    },
    onRightClick(item, index, e) {
      this.$emit("rightClick", item, index, e);
    },

    onClickDelete(id) {
      this.$emit("onClickDelete", id);
    },
    onClickView(id) {
      this.$emit("onClickView", id);
    },
    onChangeStatus(id) {
      this.$emit("onChangeStatus", id);
    },
    onApproveReject(id, status) {
      this.$emit("onApproveReject", { id: id, status: status });
    },
    onClickEdit(id) {
      this.$emit("onClickEdit", id);
    },
    onGetData(item) {
      this.$emit("onGetData", item);
    },
    onConvertInvoice(item) {
      this.$emit("onConvertInvoice", item);
    },
    onClickApply(id) {
      this.$emit("onClickApply", id);
    },
    onHitBottom($state) {
      console.log("here on hit bottom");
      this.$emit("hitBottom", $state);
    },
    onClickReject(id) {
      this.$emit("onClickReject", id);
    },
    onClickToApprove(id) {
      this.$emit("onClickToApprove", id);
    },
    onDownloadButtonClick(item) {
      this.$emit("onDownloadButtonClick", item);
    },
    onChangeSearchFilter(key, value) {
      if (Array.isArray(key)) {
        this.$emit("onChangeSearchFilter", key[0], key[1]);
      } else {
        this.$emit("onChangeSearchFilter", key, value);
      }
    },
    onClickMultiSelected(payload) {
      let id = payload.name.split("-")[1];
      if (payload.newValue.length || payload.oldValue.length) {
        this.$emit("onClickMultiSelected", { id: id, value: payload.newValue });
      }
    },
    async onScroll(event) {
      if (this.disabledInfiniteLoading == false) {
        const fakeState = {
          complete: function () {},
          loaded: function () {},
          error: function () {},
          reset: function () {},
        };
        let total =
          Number(event.target.scrollTop) +
          Number(event.target.clientHeight) +
          15;
        let scrollHeight = event.target.scrollHeight;

        if (total >= scrollHeight) {
          await this.onHitBottom(fakeState);
        }
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/sass/components/_variables.demo.scss";

*:focus {
  outline: none;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fff !important;
}

.checkbox:after,
.checkbox:before {
  z-index: 1000 !important;
}

.table_select {
  margin-left: -100px !important;
}

.invoice-pre-accounting {
  color: rgb(255, 113, 60) !important;
}

.invoice-manager {
  color: rgb(255, 118, 30) !important;
}

.invoice-accounting {
  color: rgb(255, 116, 18) !important;
}

.invoice-approved {
  color: rgb(0, 194, 0) !important;
}

.invoice-rejected {
  color: rgb(255, 44, 61) !important;
}
.sticky-header-table .b-table thead > tr > th {
  position: sticky !important;
  top: 0;
  z-index: 3;
}
.table-success,
.table-success > th,
.table-success > td {
  background-color: #befbcc !important;
}
.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #fed1d1 !important;
}
.table-default,
.table-default > th,
.table-default > td {
  background-color: #FEFF64 !important;
}
.sticky-header-table .b-table thead > tr > th {
  position: sticky !important;
  top: 0;
  z-index: 3;
}
.custom_column_date {
  width: 50%;
  padding:0px;
  margin:0px;
  text-align: center;
  background: #c0edf0;
}
.custom_column_amount {
  width: 50%;
  padding:0px;
  margin:0px;
  text-align: center;
  background: #4be3ee;
}
.custom_column_empty {
  width: 50%;
  padding:0px;
  margin:0px;
  text-align: center; 
  display: none;
}
.table th, .table td {
  padding: 0.5rem !important;
}
.table-warning, .table-warning > th, .table-warning > td{
  background: #FEFF64 !important;
}
</style>
