<template>
  <div class="container">
    <p class="endorsement-title"> 
      {{ change_chart  ?  $t("Müşteri Yükleme Termin Oranı") : 'Ekip '+$t("menu.loading_deadline_report") }}
    </p>
    <button @click="changeChart" class="btn btn-success btn-sm mb-3" v-if="!donutShow"> 
      {{ change_chart ?  $t("Ekip Grafiği") : $t("Müşteri Grafiği") }}
    </button>
    <div class="row">
      <div class="col-md-6">
        <div
          class="graphic-container"
          style="border-right: 2px solid grey"
          v-if="!donutShow && !change_chart"
        >
          <apexchart
            type="bar"
            height="400"
            :options="chartOptions"
            :series="series"
            width="520px"
            style="padding: 20px"
            @click="handleChartClick"
          ></apexchart>
        </div>
        <div
          class="graphic-container"
          style="border-right: 2px solid grey"
          v-if="!donutShow && change_chart"
        >
          <apexchart
            type="bar"
            height="400"
            :options="chartCompanyOptions"
            :series="seriesCompany"
            width="520px"
            style="padding: 20px"
            @click="handleCompanyChartClick"
          ></apexchart>
        </div>

        <div style="display: flex; justify-content: center;" v-if="donutShow">
          <span  class="option-bar-title" style="border:1px solid orange;border-radius: 10px;">{{ clickData.team_name ?? clickData.company_name }}</span>            
        </div>
        <div>
          <button @click="closeDonut()" class="closeButton" v-if="donutShow">
            X
          </button>
          <div class="graphic-container" v-if="donutShow">            
            <div>
              <div class="donutOrderText">
                <p>TOPLAM SİPARİŞ ADETİ</p>
                <p>{{ clickData.orderCount | formatNumber }}</p>
              </div>
              <div class="donutOrderAmountText">
                <p>SİPARİŞ MİKTARI</p>
                <p>{{ clickData.orderAmount }}</p>
              </div>
            </div>
            <apexchart
              type="donut"
              height="400"
              width="500px"
              style="padding: 20px"
              :options="chartOptionsDonut"
              :series="donutSeries"
            ></apexchart>
          </div>
        </div>
      </div> 
      
      <div class="col-md-2 reset-col">
        <div class="option-bar">
          <div class="option-bar-title black hover" ref="childButton" @click="parentTableChange('order')">
            <span>Sipariş Miktarı :</span>
            <span>{{
              tempChartTotalData.totalOrderAmount ? tempChartTotalData.totalOrderAmount : 0 | formatNumber
            }}</span>
          </div>
          <div class="option-bar-title green hover" @click="parentTableChange('in_time')">
            <span>Zamanında :</span>
            <span>{{
              tempChartTotalData.totalZamanindaAmount ? tempChartTotalData.totalZamanindaAmount  : 0 | formatNumber
            }}</span>
          </div>
          <div class="option-bar-title red hover" @click="parentTableChange('delay')">
            <span class="float-start">Gecikme :</span>
            <span class="float-end">{{
              tempChartTotalData.totalGecikmedeAmount ? tempChartTotalData.totalGecikmedeAmount: 0 | formatNumber
            }}</span>
          </div>
          <div class="option-bar-title yellow">
            <span>Devam Eden :</span>
            <span>{{
              tempChartTotalData.totalDevamEdenlerAmount ? tempChartTotalData.totalDevamEdenlerAmount: 0 | formatNumber
            }}</span>
          </div>
        </div>
      </div>
      <div class="col-md-4" style="margin-top:50px">
        <div class="graphic-container">
          <apexchart
            type="pie"
            height="350"
            :options="chartOptionsPieChart"
            :series="pieChartSeries"
            width="350px"
            style="padding: 20px" 
          ></apexchart>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { GET_ITEMS, LOADING } from "@/core/services/store/REST.module";
import VueApexCharts from "vue-apexcharts";
import { mapGetters } from "vuex";
import { LARAVEL_DATE_FORMAT } from "@/core/config/constant";
import * as _ from "lodash";
import moment from "moment";
export default {
  name: "LoadingDeadlineReportChart",
  computed: {
    ...mapGetters({
      loading: LOADING,
    }),
    chartOptionsPieChart() {
      let tmpChartOptions = {
        chart: {
          width: 380,
          type: "pie",
        },
        colors: ["#1FE757", "#FD1515", "#E8ED0C"],
        title: {
          text: "Genel Özet",
          style: {
            fontSize: "13px",
            fontWeight: "bold",
            color: "#5cb7e0",
          },
        },
        labels: ["Zamanında", "Gecikme", "Devam Eden"],
        legend: {
          show: true,
          position: "bottom",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                show: false,
                position: "bottom",
              },
            },
          },
        ],
      };
      return tmpChartOptions;
    },
    chartOptionsDonut() {
      let tmpChartOptions = {
        chart: {
          type: "donut",
        },
        colors: ["#1FE757", "#FD1515", "#E8ED0C"],
        title: {
          text: "Sipariş Bazında Pay Grafiği",
          style: {
            fontSize: "13px",
            fontWeight: "bold",
            color: "#5cb7e0",
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        labels: ["Zamanında", "Gecikme", "Devam Eden"],
        legend: {
          position: "right",
          offsetY: 0,
          height: 230,
        },
      };
      return tmpChartOptions;
    },
    chartOptions() {
      let tmpChartOptions = {
        chart: {
          type: "bar",
          height: 400,
          stacked: true,
          stackType: "100%",
        },
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        stroke: {
          width: 1,
          colors: ["#fff"],
        },
        title: {
          text: "Ekip Termin Raporu",
          style: {
            fontSize: "13px",
            fontWeight: "bold",
            color: "#5cb7e0",
          },
        },
        xaxis: {
          categories: this.chartCategories,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + " Adet";
            },
          },
        },
        fill: {
          opacity: 1,
        },
        legend: {
          position: "top",
          horizontalAlign: "left",
          offsetX: 40,
          onItemClick: {
            toggleDataSeries: false,
          },
        },
      };
      return tmpChartOptions;
    },
    chartCompanyOptions() {
      let tmpChartOptions = {
        chart: {
          type: "bar",
          height: 400,
          stacked: true,
          stackType: "100%",
        },
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        stroke: {
          width: 1,
          colors: ["#fff"],
        },
        title: {
          text: "Müşteri Termin Raporu",
          style: {
            fontSize: "13px",
            fontWeight: "bold",
            color: "#5cb7e0",
          },
        },
        xaxis: {
          categories: this.chartCompanyCategories,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + " Adet";
            },
          },
        },
        fill: {
          opacity: 1,
        },
        legend: {
          position: "top",
          horizontalAlign: "left",
          offsetX: 40,
          onItemClick: {
            toggleDataSeries: false,
          },
        },
      };
      return tmpChartOptions;
    },
  },
  components: {
    apexchart: VueApexCharts,
  },
  data() {
    return {
      chartCategories: [],
      chartItems: [],
      chartTotalData: [],
      series: [],
      gecikmedeData: [],
      zamanindaData: [],      
      seriesCompany: [],
      chartCompanyItems: [],
      chartCompanyCategories: [], 
      chartCompanyTotalData: [],
      gecikmedeCompanyData: [],
      zamanindaCompanyData: [],
      donutShow: false,
      donutChartData: [],
      donutSeries: [],
      clickData: {},
      pieChartSeries: [],
      tempChartTotalData: [],
      change_chart:false
    };
  },

  props: ["startDate", "endDate", "onClickButton", "item-data", "filters"],
  mounted() {
    this.getItems();
  },
  created() {
  },
  methods: {
    changeChart(){
      this.change_chart = !this.change_chart
    }, 
    closeDonut() {
      this.donutShow = false;
      this.pieChartSeries = [
        this.chartTotalData.totalZamanindaAmount ? this.chartTotalData.totalZamanindaAmount : 0,
        this.chartTotalData.totalGecikmedeAmount ? this.chartTotalData.totalGecikmedeAmount :  0,
        this.chartTotalData.totalDevamEdenlerAmount ? this.chartTotalData.totalDevamEdenlerAmount: 0,
      ];
      this.filters.team_id = []
      this.filters.company_id = []
      this.$emit("revizeFilter", this.filters); 
    },
    async getItems() {
      this.zamanindaData = [];
      this.gecikmedeData = [];
      this.chartCategories = [];

      //Company
      this.seriesCompany = [];
      this.zamanindaCompanyData = [];
      this.gecikmedeCompanyData = [];
      this.chartCompanyCategories = [];
      this.pieChartSeries=[];
      this.tempChartTotalData=[];
      this.series = [
        {
          name: "Zamanında",
          data: this.zamanindaData,
          color: "#2BAA1E",
        },
        {
          name: "Gecikme",
          data: this.gecikmedeData,
          color: "#B40407",
        },
      ];
      
      this.seriesCompany = [
        {
          name: "Zamanında",
          data: this.zamanindaCompanyData,
          color: "#2BAA1E",
        },
        {
          name: "Gecikme",
          data: this.gecikmedeCompanyData,
          color: "#B40407",
        },
      ];
      let filters = this.filters;
      this.$set(
        filters,
        "start_date",
        moment(this.startDate).format(LARAVEL_DATE_FORMAT)
      );
      this.$set(
        filters,
        "end_date",
        moment(this.endDate).format(LARAVEL_DATE_FORMAT)
      );

      this.$store
        .dispatch(GET_ITEMS, {
          filters: filters,
          url: "api/report/loading-deadline-chart-data",
        })
        .then((result) => {
          let self = this;          

          this.chartItems = result.data.expodeData;
          this.chartTotalData = result.data.totalData;
          this.tempChartTotalData = result.data.totalData;
          if(this.chartItems && this.chartItems.length > 0){
            this.chartItems.forEach((chartData, key) => {
              self.chartCategories.push(chartData.team_name);
              self.zamanindaData.push(chartData.zamaninda);
              self.gecikmedeData.push(chartData.gecikmede);
            });
          }

          this.pieChartSeries = [
            this.chartTotalData.totalZamanindaAmount ? this.chartTotalData.totalZamanindaAmount : 0,
            this.tempChartTotalData.totalGecikmedeAmount ?? 0,
            this.tempChartTotalData.totalDevamEdenlerAmount ?? 0,
          ];

          //Company 
          this.chartCompanyItems = result.data.companyExpodeData;
          this.chartCompanyTotalData = result.data.companyTotalData;
          this.tempChartCompanyTotalData = result.data.companyTotalData;
          this.chartCompanyItems.forEach((chartCompanyData, key) => {
            self.chartCompanyCategories.push(chartCompanyData.company_name);
            self.zamanindaCompanyData.push(chartCompanyData.zamaninda);
            self.gecikmedeCompanyData.push(chartCompanyData.gecikmede);
          }); 

          this.$emit("totalDataList", this.tempChartTotalData);
        }); 
    },
    handleChartClick(event, chartContext, config) {
      if (config.dataPointIndex !== undefined) {
        this.donutSeries = [];
        const clickedData = this.series[config.seriesIndex].data[config.dataPointIndex];
        let clickData = this.chartItems[config.dataPointIndex];
        this.clickData = clickData;

        this.filters.team_id = JSON.stringify([this.clickData.team_id]);
        this.$emit("revizeFilter", this.filters);  

        this.donutSeries = [
          clickData.zamaninda,
          clickData.gecikmede,
          clickData.devameden,
        ];

        this.pieChartSeries = [
          this.chartTotalData.totalZamanindaAmount ? this.chartTotalData.totalZamanindaAmount : 0,
          this.clickData.totalGecikmedeAmount ? this.clickData.totalGecikmedeAmount: 0,
          this.clickData.totalDevamEdenlerAmount ? this.clickData.totalDevamEdenlerAmount: 0,
        ];
        this.tempChartTotalData.totalOrderAmount =
          this.clickData.totalOrderAmount ? this.clickData.totalOrderAmount :  0;
        this.tempChartTotalData.totalZamanindaAmount =
          this.chartTotalData.totalZamanindaAmount ? this.chartTotalData.totalZamanindaAmount : 0;
        this.tempChartTotalData.totalGecikmedeAmount =
          this.clickData.totalGecikmedeAmount ? this.clickData.totalGecikmedeAmount : 0;
        (this.tempChartTotalData.totalDevamEdenlerAmount =
          this.clickData.totalDevamEdenlerAmount ? this.clickData.totalDevamEdenlerAmount : 0),
          (this.donutShow = true);
      }
    },
    handleCompanyChartClick(event, chartContext, config) {
      if (config.dataPointIndex !== undefined) {
        this.donutSeries = [];
        const clickedData = this.series[config.seriesIndex].data[config.dataPointIndex];
        let clickData = this.chartCompanyItems[config.dataPointIndex];
        this.clickData = clickData; 

        this.filters.company_id = JSON.stringify([this.clickData.company_id]);
        this.$emit("revizeFilter", this.filters);  

        this.donutSeries = [
          clickData.zamaninda,
          clickData.gecikmede,
          clickData.devameden,
        ];

        this.pieChartSeries = [
          this.chartTotalData.totalZamanindaAmount ? this.chartTotalData.totalZamanindaAmount : 0,
          this.clickData.totalGecikmedeAmount ? this.clickData.totalGecikmedeAmount: 0,
          this.clickData.totalDevamEdenlerAmount ? this.clickData.totalDevamEdenlerAmount: 0,
        ];
        this.tempChartTotalData.totalOrderAmount =
          this.clickData.totalOrderAmount ? this.clickData.totalOrderAmount :  0;
        this.tempChartTotalData.totalZamanindaAmount =
          this.chartTotalData.totalZamanindaAmount ? this.chartTotalData.totalZamanindaAmount : 0;
        this.tempChartTotalData.totalGecikmedeAmount =
          this.clickData.totalGecikmedeAmount ? this.clickData.totalGecikmedeAmount : 0;
        (this.tempChartTotalData.totalDevamEdenlerAmount =
          this.clickData.totalDevamEdenlerAmount ? this.clickData.totalDevamEdenlerAmount : 0),
          (this.donutShow = true);
      }
    },
    parentTableChange(type)
    { 
      this.$emit('parentTableChange',type)  
    }
  }, 
  watch: {
    onClickButton: function (newVal, oldVal) {
      if (newVal == true) {
        this.getItems();
      }
    },
  },
  filters: {
    formatNumber(value) {
      if (!value || isNaN(value)) {
        return "0";
      }
      return new Intl.NumberFormat("tr-TR").format(value);
    },
  },
};
</script>
<style scoped>
.endorsement-title {
  font-size: 18px;
  font-weight: 800;
  text-align: center;
  margin-top: 5px;
  color: #5cb7e0;
}
.graphic-container {
  overflow-x: auto;
  display: flex;
  position: relative;
  flex-wrap: nowrap;
}
/* Scrollbar tasarımı */
.graphic-container::-webkit-scrollbar {
  height: 8px;
}

.graphic-container::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.graphic-container::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

.graphic-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.closeButton {
  position: absolute;
  left: -5px;
  color: white;
  background: red;
  border-radius: 50%;
  padding: 5px 10px;
  top: 10px;
  z-index: 2;
}

.donutOrderText {
  position: absolute;
  top: 45%;
  left: 35%;
  transform: translate(-50%, -50%);
  width: 160px;
  text-align: center;
}
.donutOrderAmountText p,
.donutOrderText p {
  font-size: 16px;
  font-weight: 800;
  line-height: 1.2;
}
.donutOrderAmountText p:nth-child(2),
.donutOrderText p:nth-child(2) {
  font-size: 24px;
  font-weight: 800;
  line-height: 1;
  color: rgb(209, 18, 18);
}

.donutOrderAmountText {
  position: absolute;
  top: 64%;
  left: 35%;
  transform: translate(-50%, -50%);
  width: 180px;
  text-align: center;
}
.option-bar {
  align-content: center;
}
.option-bar-title {
  cursor:pointer;
  font-size: 12px;
  font-weight: 800;
  line-height: 1;
  padding: 10px 20px;
  margin: 10px 0px;
  justify-content: space-between;
  line-height: 1.2;
}
.option-bar-title span:nth-child(1) {
  width: 100px;
}
.option-bar-title span:nth-child(2) {
  float: right;
}
.black,
.green,
.red,
.yellow {
  border: 1px solid;
  border-radius: 10px;
}
.black {
  border-color: #000000;
  box-shadow: 0 0 10px 0 #000000;
}
.green {
  border-color: #1fe757;
  box-shadow: 0 0 10px 0 #1fe757;
}
.red {
  border-color: #fd1515;
  box-shadow: 0 0 10px 0 #fd1515;
}
.yellow {
  border-color: #e8ed0c;
  box-shadow: 0 0 10px 0 #e8ed0c;
}
.hover:hover{
  font-size: 13px;
  color:orange
}
.reset-col {
  align-content: center;
  padding: 0px;
}

.graphic-container tspan {
  float: left;
}
</style>
